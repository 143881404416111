<template>
  <div class="home">
    <v-container class="grey lighten-5">
      <v-row>
        <v-col>
          <h2 class="font-weight-light mb-3">Alle Wandelaars ({{alleWandelaars}})</h2>

          <v-list dense>
            <v-subheader
              >Wie lopen er wel eens? En wie vaker dan anderen?</v-subheader
            >
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in this.allUsers" :key="i">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-walk</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.naam" class="font-weight-light"></v-list-item-title>
                  <v-list-item-action-text
                    >{{ item.count }} loopjes totaal</v-list-item-action-text
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <!-- {{allUsers}} -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped>
 .item__title{   
    font-size: 1.15rem;
    line-height: 1.5rem;
}
</style>
<script>
import { mapGetters } from "vuex";
import { usersRef, counterRef } from "../store/firestore";
//import store from "../store";

export default {
  name: "Home",
  components: {},
  data: () => ({
    allUsers: [],
    errorMessage: "",
    currentOrder: "value",
    alleWandelaars: '...'
  }),
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    orderedNames() {
      return this.allUsers.sort((a, b) => a.naam.localeCompare(b.naam));
    },

    getAllUsers() {
      var that = this;
      // haal alle users op in de toekomst
      counterRef
        .get()
        .then(function (counterTotals) {
          // haal alle counts op
          var counts = [];
          // console.log(counterTotals.docs[0].data());
          counterTotals.forEach(function (counter) {
            counts[counter.id] = counter.data().count;
          });
          usersRef.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var test = doc.data();
              test.id = doc.id;
              test.count = counts[doc.data().uid];
              that.allUsers.push(test);
              that.alleWandelaars = that.allUsers.length;
              that.orderedNames(); //yeah sucks
            });
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    },
  },
};
</script>